import styled from 'styled-components'

import deviceMinWidth from '../../styles/deviceMinWidth'
import { miscStyles } from '../../styles/misc'
import { colors } from '../../styles/colors'

export const LibraBubbleBody = styled.div`
	max-height: ${(props) =>
		props.$phase === 'speaking' || (props.$phase === 'listening' && !props.$isSpeaking)
			? `calc(100vh - ${(props.$withTitle ? 56 : 0) + (props.$isListening ? 206 : 0)}px)`
			: props.$phase === 'listening'
				? '32px'
				: undefined};
	@supports (height: 100dvh) {
		max-height: ${(props) =>
			props.$phase === 'speaking' || (props.$phase === 'listening' && !props.$isSpeaking)
				? `calc(100dvh - ${(props.$withTitle ? 56 : 0) + (props.$isListening ? 206 : 0)}px)`
				: props.$phase === 'listening'
					? '32px'
					: undefined};
	}
	@media ${deviceMinWidth.tablet} {
		max-height: ${(props) =>
			!props.$withSideDash && !props.$withTitle
				? 'unset'
				: !props.$withSideDash
					? 'calc(100vh - 144px)'
					: undefined};
		@supports (height: 100dvh) {
			max-height: ${(props) =>
				!props.$withSideDash && !props.$withTitle
					? 'unset'
					: !props.$withSideDash
						? 'calc(100dvh - 144px)'
						: undefined};
		}
	}

	overflow: auto;

	padding: 8px;

	// border-radius: 24px;
	border-top-right-radius: ${(props) => (props.$phase === 'listening' ? 0 : '24px')};
	border-bottom-right-radius: 24px;
	border-bottom-left-radius: ${(props) => (props.$phase === 'speaking' ? 0 : '24px')};
	border-top-left-radius: 24px;

	// border: ${(props) =>
		props.$phase === 'speaking' ? `1px solid ${colors.libraStar}` : undefined};

	background: ${(props) => props.theme.glassBackgroundColor};
	@supports not (
		(-webkit-backdrop-filter: ${miscStyles.glassBackdropFilter}) or
			(backdrop-filter: ${miscStyles.glassBackdropFilter})
	) {
		background: ${(props) => props.theme.backgroundColor};
	}

	-webkit-backdrop-filter: ${(props) => props.theme.glassBackdropFilter};
	backdrop-filter: ${(props) => props.theme.glassBackdropFilter};

	box-shadow: ${(props) => props.theme.boxShadow};
`

export const LibraBubbleImage = styled.div`
	display: flex;
	justify-content: center;

	.ant-image-mask {
		border-radius: 16px;
	}
	.ant-image-img {
		max-height: ${(props) => (props.$withSpeech ? 'min(352px, calc(100vw - 32px))' : undefined)};
		max-height: ${(props) =>
			!props.$withSpeech && (props.$isListening || props.$withTitle)
				? `calc(100vh - ${(props.$withTitle ? 56 : 0) + (props.$isListening ? 206 : 0)}px)`
				: undefined};
		@supports (height: 100dvh) {
			max-height: ${(props) =>
				!props.$withSpeech && (props.$isListening || props.$withTitle)
					? `calc(100dvh - ${(props.$withTitle ? 56 : 0) + (props.$isListening ? 206 : 0)}px)`
					: undefined};
		}
		@media ${deviceMinWidth.tablet} {
			max-height: ${(props) =>
				!props.$withSpeech && !props.$withSideDash && !props.$withTitle
					? 'calc(100vh - 88px)'
					: !props.$withSpeech && !props.$withSideDash
						? 'calc(100vh - 144px)'
						: undefined};
			@supports (height: 100dvh) {
				max-height: ${(props) =>
					!props.$withSpeech && !props.$withSideDash && !props.$withTitle
						? 'calc(100dvh - 88px)'
						: !props.$withSpeech && !props.$withSideDash
							? 'calc(100dvh - 144px)'
							: undefined};
			}
		}

		max-width: min(352px, calc(100vw - 32px));

		border-radius: 16px;
	}
`

export const LibraBubbleText = styled.div`
	display: ${(props) => (props.$isSpeechHidden ? 'none' : undefined)};

	padding: 5px 8px;

	.ant-typography {
		margin-bottom: 0;

		> p:first-child,
		h1:first-child,
		h2:first-child,
		h3:first-child,
		h4:first-child,
		h5:first-child {
			margin-top: 0;
		}
		> p:last-child,
		> ol:last-child,
		> ul:last-child {
			margin-bottom: 0;
		}
	}
`

export const Strong = styled.strong`
	// color: ${colors.libraStar};
`

export const Em = styled.em`
	// color: ${colors.libraStar};
`
