// Function component (presentational):

import React from 'react'

import PageIllustration from '../shared/styledComponents/page/PageIllustration'
import PageAction from '../shared/styledComponents/page/PageAction'

import Tabs from '../shared/styledComponents/tabs/Tabs'
import TabPaneWrapper from '../shared/styledComponents/tabs/TabPaneWrapper'

import NavButton from '../shared/styledComponents/button/NavButton'

import Card from '../shared/styledComponents/card/Card'

import FieldContainer from '../field/FieldContainer'

import SearchResult from './SearchResult'
import SearchForm from './SearchForm'

import pattern from '../../assets/patterns/abstract-1.jpg'

import { AppstoreOutlined } from '@ant-design/icons'
// import { Tag } from 'antd'

const Library = ({
	isTile,
	setSearchTerm,
	pageNumber,
	pageCount,
	initialValues,
	isNutrition,
	isMind,
	isFitness,
	scope,
	toggleDisplay,
	handleSectionChange,
	implicitField,
	options,
	loading,
	searchData,
	searchMoreData,
	dataSource,
	t,
	// isLaptop,
	isDarkTheme,
}) => {
	const items = []

	const children = (
		<TabPaneWrapper key="search-results">
			<SearchResult
				isTile={isTile}
				pageNumber={pageNumber}
				pageCount={pageCount}
				loading={loading}
				dataSource={dataSource}
				handleClick={searchMoreData}
				t={t}
			/>
		</TabPaneWrapper>
	)

	const standardFoodItem = {
		key: 'standardFood',
		label: t('library:scopes.standardFood.title'),
		children,
	}
	const foodItemItem = {
		key: 'foodItem',
		label: t('library:scopes.foodItem.title'),
		children,
	}
	const recipeItem = {
		key: 'recipe',
		label: t('library:scopes.recipe.title'),
		children,
	}
	const mealItem = { key: 'meal', label: t('library:scopes.meal.title'), disabled: true }
	const mealProgramItem = {
		key: 'mealProgram',
		label: t('library:scopes.mealProgram.title'),
		disabled: true,
	}
	isNutrition && items.push(standardFoodItem, foodItemItem, recipeItem, mealItem, mealProgramItem)

	const introspectionItem = {
		key: 'introspection',
		label: t('library:scopes.introspection.title'),
		children,
	}
	const introspectionProgramItem = {
		key: 'introspectionProgram',
		label: t('library:scopes.introspectionProgram.title'),
		disabled: true,
	}
	const meditationItem = {
		key: 'meditation',
		label: t('library:scopes.meditation.title'),
		disabled: true,
	}
	isMind && items.push(introspectionItem, introspectionProgramItem, meditationItem)

	const activityKindItem = {
		key: 'activityKind',
		label: t('library:scopes.activityKind.title'),
		children,
	}
	const workoutItem = { key: 'workout', label: t('library:scopes.workout.title'), disabled: true }
	const fitnessProgramItem = {
		key: 'fitnessProgram',
		label: t('library:scopes.fitnessProgram.title'),
		disabled: true,
	}
	const fitnessTestItem = {
		key: 'fitnessTest',
		label: t('library:scopes.fitnessTest.title'),
		disabled: true,
	}
	isFitness && items.push(activityKindItem, workoutItem, fitnessProgramItem, fitnessTestItem)

	return (
		<>
			<PageIllustration $src={pattern} $isDarkTheme={isDarkTheme} />

			<PageAction>
				<Card $size={'wide'} $isAction>
					<SearchForm
						initialValues={initialValues}
						setSearchTerm={setSearchTerm}
						scope={scope}
						options={options}
						loading={loading}
						searchData={searchData}
						t={t}
					/>
					{/* <div style={{ margin: '8px 8px 0 8px', display: 'flex', justifyContent: 'center' }}>
						<Tag bordered={false}>Tag 1</Tag>
						<Tag bordered={false}>Tag 2</Tag>
						<Tag bordered={false}>Tag 3</Tag>
					</div> */}
				</Card>

				<FieldContainer field={implicitField} />
			</PageAction>

			<Tabs
				activeKey={scope}
				tabBarExtraContent={{
					left: <NavButton icon={<AppstoreOutlined />} onClick={toggleDisplay} />,
				}}
				items={items}
				onChange={handleSectionChange}
				$withLeftExtra
			></Tabs>
		</>
	)
}

export default Library
