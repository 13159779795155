// Function component (container, using React, i18next & React Responsive hooks):

// React State & Effect hooks.
import React, { useState, useEffect, useCallback } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import build from 'redux-object'

import { accountableTypes } from '../../utils/yupSchema/accountsSchema'

import { scansIndex } from '../../ducks/scans'
import { toggleNewEvent, setMeal } from '../../ducks/event'

import L10nLink from '../shared/navigation/L10nLink'

import { BarcodeOutlined, PlusOutlined } from '@ant-design/icons'
import { Avatar, Image, Button } from 'antd'

import SearchResult from '../library/SearchResult'

const ScanHistoryStatefulContainer = ({ isTile }) => {
	const dispatch = useDispatch()

	const { getAccessTokenSilently, isAuthenticated } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation(['scanner'])

	const [pageNumber, setPageNumber] = useState(0)
	const [pageCount, setPageCount] = useState(0)

	const [loading, setLoading] = useState(false)

	const { currentAccountableType, currentAccountId } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const person = currentAccountableType === accountableTypes[0] && currentAccount.accountable
	const personId = person?.id

	const listScans = useCallback(
		async (page = 1) => {
			if (pageNumber === page) return

			if (page === 1) {
				setPageNumber(0)
				setPageCount(0)
			}
			setLoading(true)

			const accessToken = await getAccessTokenSilently()

			const { response } = await dispatch(
				scansIndex(language, accessToken, { of: personId }, { size: 12, number: page }),
			)

			if (response) {
				const q = Object.values(response.meta)[0]

				const {
					meta: { pages },
				} = q
				setPageNumber(page)
				setPageCount(pages)
				setLoading(false)
			}
		},
		[pageNumber, personId, getAccessTokenSilently, language, dispatch],
	)

	const shouldListScans = personId && pageNumber === 0
	useEffect(() => {
		shouldListScans && listScans()
	}, [shouldListScans, listScans])

	const handleFoodClick = (foodId) => {
		dispatch(setMeal({ foodId }))
		dispatch(toggleNewEvent())
	}

	const foodItems = build(apiData, 'scan')
		?.filter((s) => s.codeFormat !== 'qr_code')
		?.filter((s) => s.person?.id === personId)
		?.map((s) => s.foodItem)
		?.filter((v, i, a) => a.indexOf(v) === i)
	const dataSource = foodItems?.map(
		({
			id,
			food: { image, id: foodId, description },
			brandOwnerName,
			brandName,
			subbrandName,
		}) => ({
			// type: 'foodItem',
			id,
			url: image?.url && <Image src={image.mediumUrl} preview={{ src: image.url }} />,
			avatar: (
				<Avatar
					icon={<BarcodeOutlined />}
					src={image?.url && <Image src={image?.thumbUrl} preview={{ src: image.url }} />}
					size={44}
					shape="square"
				/>
			),
			title: <L10nLink to={`/library/food-item/${id}`}>{description}</L10nLink>,
			extra: isAuthenticated && (
				<Button shape="circle" icon={<PlusOutlined />} onClick={() => handleFoodClick(foodId)} />
			),
			description: [brandOwnerName, brandName, subbrandName].filter(Boolean).join(', '),
		}),
	)

	const listMoreScans = () => listScans(pageNumber + 1)

	return (
		<SearchResult
			isTile={isTile}
			pageNumber={pageNumber}
			pageCount={pageCount}
			loading={loading}
			dataSource={dataSource}
			handleClick={listMoreScans}
			t={t}
		/>
	)
}

export default ScanHistoryStatefulContainer
