// Function component (presentational):

import React from 'react'

import CardList from './CardList'

import Card from '../shared/styledComponents/card/Card'

import renderLabel from '../shared/other/renderLabel'

import { ApiOutlined, SyncOutlined } from '@ant-design/icons'
import { Empty } from 'antd'
import BlockRoundButton from '../shared/styledComponents/button/BlockRoundButton'

const SearchResult = ({ isTile, dataSource, pageNumber, pageCount, loading, handleClick, t }) => {
	const hasSearched = pageNumber === 1
	const endedList = pageNumber === pageCount
	const loadMore = hasSearched && !endedList && !loading
	const withData = dataSource?.length > 0
	const showEmpty = hasSearched && !withData

	const renderLoadMoreCard = () => (
		<Card style={{ height: '100%', marginTop: 'auto' }}>
			<BlockRoundButton type="default" onClick={handleClick}>
				{renderLabel({
					icon: <ApiOutlined />,
					label: t('translation:ui.api.loadMore'),
					extra: <SyncOutlined />,
				})}
			</BlockRoundButton>
		</Card>
	)

	return (
		<>
			<CardList isTile={isTile} dataSource={dataSource} loading={loading} />
			{loadMore && renderLoadMoreCard()}
			{showEmpty && <Empty />}
		</>
	)
}

export default SearchResult
