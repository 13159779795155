import { fetchLibraDietApiData } from './apiData'
import { localizePath, initRequestOptions } from '../utils/api'

const path = '/scans'

export const scansIndex =
	(locale, accessToken, filter = {}, page = {}) =>
	(dispatch) => {
		const endpoint = localizePath(locale, path)
		const options = initRequestOptions({ accessToken })
		const params = { filter, page }

		return dispatch(fetchLibraDietApiData({ endpoint, options, params }))
	}

export const scansCreate = (locale, scanAttrs) => (dispatch) => {
	const endpoint = localizePath(locale, path)
	const method = 'POST'
	const data = { scan: scanAttrs }
	const options = initRequestOptions({ method, data })

	return dispatch(fetchLibraDietApiData({ endpoint, options }))
}
