// Function component (container, using React, i18next & React Responsive hooks):

// React State & Effect hooks.
import React, { useState, useEffect, useCallback } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hook for routing.
import { useSearchParams, useNavigate } from 'react-router-dom'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
// import { useMediaQuery } from 'react-responsive'

import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode'
import build from 'redux-object'

import { accountableTypes } from '../../utils/yupSchema/accountsSchema'
import { gtinFormats } from '../../utils/yupSchema/scansSchema'

import { prefixPath } from '../../utils/l10n'
import { gaEvents } from '../../utils/gaEvents'
import { signIn } from '../../utils/auth'

import { scansCreate } from '../../ducks/scans'

// import deviceMinWidth from '../../styles/deviceMinWidth'

import Scanner from './Scanner'

import { App as AntApp } from 'antd'

const ScannerStatefulContainer = () => {
	const { isAuthenticated, loginWithRedirect } = useAuth0()

	let [searchParams, setSearchParams] = useSearchParams()

	const fallbackSection = isAuthenticated ? 'history' : 'popularity'
	const paramsSection = searchParams.get('section')
	const initialSection = paramsSection ?? fallbackSection

	const [section, setSection] = useState(initialSection)
	const handleSectionChange = (key) => setSection(key)

	const shouldSetSearchParams = section !== initialSection
	useEffect(() => {
		shouldSetSearchParams && setSearchParams({ section })
	}, [shouldSetSearchParams, section, setSearchParams])

	const [isReaderVisible, setReaderVisible] = useState(true)
	const toggleReader = () => setReaderVisible((v) => !v)

	const [cameras, setCameras] = useState([])
	const withCameras = cameras.length

	const { message } = AntApp.useApp()

	let navigate = useNavigate()

	const {
		i18n: { language },
		t,
	} = useTranslation(['scanner', 'scans'])

	const shouldSetCameras = isReaderVisible && !withCameras
	useEffect(() => {
		if (!shouldSetCameras) return

		Html5Qrcode.getCameras()
			.then((devices) => {
				if (devices?.length) {
					setCameras(devices)
				}
			})
			.catch((_e) => {
				message.warning({
					content: t('scanner:camera.message.error'),
					key: 'cameraError',
				})

				toggleReader()
			})
	}, [shouldSetCameras, message, navigate, language, t])

	const [text, setText] = useState('')
	const [codeFormat, setCodeFormat] = useState('')

	const shouldStartScan = withCameras
	useEffect(() => {
		if (!shouldStartScan) return

		const formatsToSupport = [
			Html5QrcodeSupportedFormats.EAN_13,
			Html5QrcodeSupportedFormats.UPC_A,
			Html5QrcodeSupportedFormats.UPC_E,
			Html5QrcodeSupportedFormats.EAN_8,
			Html5QrcodeSupportedFormats.QR_CODE,
		]
		const html5QrCode = new Html5Qrcode('reader', { formatsToSupport })

		const config = { fps: 20, qrbox: { width: 224, height: 224 } }

		const onScanSuccess = (decodedText, decodedResult) => {
			const {
				result: {
					format: { formatName },
				},
			} = decodedResult

			if (decodedText === text) return

			setText(decodedText)
			setCodeFormat(formatName.toLowerCase())
		}
		html5QrCode.start({ facingMode: 'environment' }, config, onScanSuccess)
	}, [shouldStartScan, text])

	const { currentAccountableType, currentAccountId } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const person = currentAccountableType === accountableTypes[0] && currentAccount.accountable
	const personId = person?.id

	const dispatch = useDispatch()

	const navToFoodItem = useCallback(
		(gtin = text) => {
			navigate(prefixPath(`/library/food-item/${gtin}`, language))
		},
		[text, language, navigate],
	)

	const handleScan = useCallback(() => {
		gaEvents.scan(text, codeFormat)

		dispatch(scansCreate(language, { text, codeFormat, personId }))

		if (codeFormat === 'qr_code') {
			const urlObj = new URL(text)
			const { host, pathname } = urlObj

			if (host === 'app.libra.diet') {
				navigate(pathname)
			} else {
				message.warning({
					content: t('scanner:qRCode.message.warning'),
					key: 'qRCodeWarning',
				})
			}
		} else if (!gtinFormats.includes(codeFormat)) {
			message.warning({
				content: t('scanner:gtin.message.warning'),
				key: 'gtinWarning',
			})
		} else {
			navToFoodItem()
		}
	}, [text, codeFormat, personId, language, dispatch, t, message, navigate, navToFoodItem])

	const shouldHandleScan = text && codeFormat
	useEffect(() => {
		shouldHandleScan && handleScan()
	}, [shouldHandleScan, handleScan])

	const initialValues = { text: '' }

	const handleLogin = () => signIn(loginWithRedirect)

	const [isTile, setTile] = useState(false)

	const toggleTile = () => setTile((v) => !v)

	// const isLaptop = useMediaQuery({
	// 	// query: '(min-width: 1024px)'
	// 	query: `${deviceMinWidth.laptop}`,
	// })

	const { isDarkTheme } = useSelector((state) => state.appearance)

	return (
		<Scanner
			isAuthenticated={isAuthenticated}
			section={section}
			handleSectionChange={handleSectionChange}
			isReaderVisible={isReaderVisible}
			toggleReader={toggleReader}
			// isTorchAvailable={isTorchAvailable}
			// toggleTorch={toggleTorch}
			handleLogin={handleLogin}
			initialValues={initialValues}
			navToFoodItem={navToFoodItem}
			isTile={isTile}
			toggleDisplay={toggleTile}
			t={t}
			// isLaptop={isLaptop}
			isDarkTheme={isDarkTheme}
		/>
	)
}

export default ScannerStatefulContainer
