// Function component (presentational):

import React, { Suspense } from 'react'

import { doDownload } from '../../utils/file'

import PageIllustration from '../shared/styledComponents/page/PageIllustration'
import PageAction from '../shared/styledComponents/page/PageAction'

import Card from '../shared/styledComponents/card/Card'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'
import NavButton from '../shared/styledComponents/button/NavButton'

import renderLabel from '../shared/other/renderLabel'
import renderSkeletonCard from '../shared/other/renderSkeletonCard'

import Tabs from '../shared/styledComponents/tabs/Tabs'
import TabPaneWrapper from '../shared/styledComponents/tabs/TabPaneWrapper'

import List from '../shared/styledComponents/list/List'

import L10nLink from '../shared/navigation/L10nLink'

import NutritionFactsContainer from '../nutrition/NutritionFactsStatefulContainer'

import pattern from '../../assets/patterns/abstract-1.jpg'

import Icon, {
	LoadingOutlined,
	PlusOutlined,
	ThunderboltOutlined,
	LoginOutlined,
	HeartOutlined,
	ProfileOutlined,
	EyeOutlined,
	NodeCollapseOutlined,
	NodeExpandOutlined,
	SisternodeOutlined,
	GroupOutlined,
	UngroupOutlined,
	FileImageOutlined,
} from '@ant-design/icons'
import { ReactComponent as Nutrition } from '../../assets/icons/water-drop.svg'
import { ReactComponent as Liquid } from '../../assets/icons/water-glass.svg'
import { ReactComponent as Solid } from '../../assets/icons/fork-and-knife.svg'
import { Avatar, Image, Button, Descriptions, Statistic, QRCode, Tooltip } from 'antd'

const StandardFood = ({
	section,
	handleSectionChange,
	isAuthenticated,
	standardFood,
	handleAdd,
	handleAnalyze,
	handleLogin,
	// handleFavorite,
	t,
	// isLaptop,
	isDarkTheme,
}) => {
	const items = []

	const overviewChildren = []

	const food = standardFood?.food

	const infoItems = [
		{
			key: 'physicalState',
			label: t('standardFood:sections.overview.info.physicalState'),
			children: food?.physicalStateLabel,
			span: 2,
		},
		{
			key: 'cooked',
			label: t('standardFood:sections.overview.info.cooked'),
			children: food?.cooked?.toString(),
			span: 2,
		},
		{
			key: 'category',
			label: t('standardFood:sections.overview.info.category'),
			children: food?.categoryName,
			span: 2,
		},
	]

	const renderInfo = () => (
		<Card
			title={renderLabel({
				icon: <ProfileOutlined />,
				label: t('standardFood:sections.overview.info.title'),
			})}
			style={{ height: '100%' }}
		>
			<Descriptions column={2} items={infoItems} style={{ padding: '0 6px' }} />
		</Card>
	)

	const renderStats = ({ title, value, prefix }) => (
		<Card $size={'small'} style={{ height: '100%' }}>
			<Statistic
				title={title}
				value={value}
				prefix={prefix}
				style={{ padding: '1px 6px 6px 2px' }}
			/>
		</Card>
	)

	const viewsCount = food?.viewsCount
	const renderViews = () =>
		renderStats({
			title: t('standardFood:sections.overview.stats.views'),
			value: viewsCount,
			prefix: <EyeOutlined />,
		})

	const downloadQRCode = () => {
		const canvas = document.getElementById('qrcode')?.querySelector('canvas')
		if (canvas) {
			const url = canvas.toDataURL()
			console.log('url', url)
			doDownload(url, 'QRCode.png')
		}
	}

	const renderQRCode = () => (
		<Card id="qrcode" $size={'small'} style={{ height: '100%' }} onClick={downloadQRCode}>
			<Tooltip title={t('standardFood:sections.overview.qRCode.download')}>
				<QRCode
					value={`https://app.libra.diet/library/standard-food/${standardFood.id}`}
					icon="/libra-logo.mark.svg"
					style={{ width: '100%', height: '100%', borderRadius: 16 }}
				/>
			</Tooltip>
		</Card>
	)

	const enoughViewsCount = viewsCount >= 12
	const renderOverviewChildren = () => (
		<TabPaneWrapper key="overview-pane">
			{renderInfo()}
			{enoughViewsCount && renderViews()}
			{renderQRCode()}
		</TabPaneWrapper>
	)
	standardFood && overviewChildren.push(renderOverviewChildren())

	const overviewItem = {
		key: 'overview',
		label: t('standardFood:sections.overview.title'),
		children: overviewChildren,
	}

	const relatedFoodChildren = []

	const renderFoodLabel = ({ name, isLiquid, url, sqUrl, id }) =>
		renderLabel({
			icon: (
				<Avatar
					icon={<Icon component={!name ? Nutrition : isLiquid ? Liquid : Solid} />}
					src={url && <Image src={sqUrl} preview={{ src: url }} />}
					shape="square"
				/>
			),
			label: (
				<L10nLink
					to={{
						pathname: `/library/standard-food/${id}`,
						search: '?section=relatedFood',
					}}
				>
					{name ?? t('standardFood:page.title')}
				</L10nLink>
			),
			extra: !name ? <LoadingOutlined /> : null,
		})

	const buildData = (sfs) =>
		sfs?.map((sf) => ({
			id: sf.id,
			name: sf?.food?.name,
			isLiquid: sf?.food?.physicalState === 'liquid',
			url: sf?.cartoonImage?.url ?? sf?.food?.image?.url,
			sqUrl: sf?.cartoonImage?.thumbUrl ?? sf?.food?.image?.thumbUrl,
		}))

	const generic = standardFood?.generic
	const genericFood = generic?.food

	const renderGeneric = () => (
		<Card
			title={renderLabel({
				icon: <NodeCollapseOutlined />,
				label: t('standardFood:sections.relatedFood.generic.title'),
			})}
			style={{ height: '100%' }}
		>
			{renderFoodLabel({
				id: generic.id,
				name: genericFood?.name,
				isLiquid: genericFood?.physicalState === 'liquid',
				url: generic?.cartoonImage?.url ?? genericFood?.image?.url,
				sqUrl: generic?.cartoonImage?.thumbUrl ?? genericFood?.image?.thumbUrl,
			})}
		</Card>
	)

	const varieties = standardFood?.varieties
	const anyVarieties = varieties && Object.keys(varieties).length !== 0
	const varietiesData = anyVarieties && buildData(varieties)

	const renderVarieties = () => (
		<Card
			title={renderLabel({
				icon: <NodeExpandOutlined />,
				label: t('standardFood:sections.relatedFood.varieties.title'),
			})}
			style={{ height: '100%' }}
		>
			<List dataSource={varietiesData} renderItem={renderFoodLabel} />
		</Card>
	)

	const relatives = standardFood?.relatives
	const anyRelatives = relatives && Object.keys(relatives).length !== 0
	const relativesData = anyRelatives && buildData(relatives)

	const renderRelatives = () => (
		<Card
			title={renderLabel({
				icon: <SisternodeOutlined />,
				label: t('standardFood:sections.relatedFood.relatives.title'),
			})}
			style={{ height: '100%' }}
		>
			<List dataSource={relativesData} renderItem={renderFoodLabel} />
		</Card>
	)

	const whole = standardFood?.whole
	const wholeFood = whole?.food

	const renderWhole = () => (
		<Card
			title={renderLabel({
				icon: <GroupOutlined />,
				label: t('standardFood:sections.relatedFood.whole.title'),
			})}
			style={{ height: '100%' }}
		>
			{renderFoodLabel({
				id: whole.id,
				name: wholeFood?.name,
				isLiquid: wholeFood?.physicalState === 'liquid',
				url: whole?.cartoonImage?.url ?? wholeFood?.image?.url,
				sqUrl: whole?.cartoonImage?.thumbUrl ?? wholeFood?.image?.thumbUrl,
			})}
		</Card>
	)

	const parts = standardFood?.parts
	const anyParts = parts && Object.keys(parts).length !== 0
	const partsData = anyParts && buildData(parts)

	const renderParts = () => (
		<Card
			title={renderLabel({
				icon: <UngroupOutlined />,
				label: t('standardFood:sections.relatedFood.parts.title'),
			})}
			style={{ height: '100%' }}
		>
			<List dataSource={partsData} renderItem={renderFoodLabel} />
		</Card>
	)

	const renderRelatedFoodChildren = () => (
		<TabPaneWrapper key="related-food-pane">
			{generic && renderGeneric()}
			{anyVarieties && renderVarieties()}
			{anyRelatives && renderRelatives()}
			{whole && renderWhole()}
			{anyParts && renderParts()}
		</TabPaneWrapper>
	)

	relatedFoodChildren.push(renderRelatedFoodChildren())

	const anyRelatedFood = generic || anyVarieties || whole || anyParts

	const relatedFoodItem = {
		key: 'relatedFood',
		label: t('standardFood:sections.relatedFood.title'),
		children: relatedFoodChildren,
		disabled: !anyRelatedFood,
	}

	const renderNutritionFactsChildren = () => (
		<TabPaneWrapper key="nutrition-facts-pane">
			<Suspense fallback={renderSkeletonCard()}>
				<NutritionFactsContainer food={food} />
			</Suspense>
		</TabPaneWrapper>
	)

	const nutritionFactsItem = {
		key: 'nutritionFacts',
		label: t('standardFood:sections.nutritionFacts.title'),
		children: renderNutritionFactsChildren(),
		disabled: !food?.nutritionalComposition,
	}

	const galleryChildren = []

	const image = food?.image
	const photoUrl = image?.url
	const photoMedUrl = image?.mediumUrl

	const renderPhotoGallery = () => (
		<Card>
			<Image
				src={photoUrl}
				placeholder={<Image preview={false} src={photoMedUrl} />}
				style={{ borderRadius: 16 }}
			/>
		</Card>
	)

	const botanicalImageData = standardFood?.botanicalImages ?? []

	const renderBotanicalGallery = () => (
		<Card
			title={renderLabel({
				icon: <FileImageOutlined />,
				label: t('standardFood:sections.gallery.botanical.title'),
			})}
			style={{ height: '100%' }}
		>
			<Image.PreviewGroup>
				<List
					dataSource={botanicalImageData}
					renderItem={({ url, mediumUrl }) => (
						<List.Item>
							<Image
								src={url}
								placeholder={<Image preview={false} src={mediumUrl} />}
								style={{ borderRadius: 8 }}
							/>
						</List.Item>
					)}
					grid={{ gutter: 6, column: 4 }}
					pagination={{
						pageSize: 12,
						showSizeChanger: false,
						// hideOnSinglePage: true,
						size: 'small',
						align: 'center',
					}}
				/>
			</Image.PreviewGroup>
		</Card>
	)

	const anyBotanicalImageData = botanicalImageData.length !== 0

	const renderGalleryChildren = () => (
		<TabPaneWrapper key="gallery-pane">
			{photoUrl && renderPhotoGallery()}
			{anyBotanicalImageData && renderBotanicalGallery()}
		</TabPaneWrapper>
	)

	galleryChildren.push(renderGalleryChildren())

	const anyImage = photoUrl || anyBotanicalImageData

	const galleryItem = {
		key: 'gallery',
		label: t('standardFood:sections.gallery.title'),
		children: galleryChildren,
		disabled: !anyImage,
	}

	items.push(overviewItem, relatedFoodItem, nutritionFactsItem, galleryItem)

	const isLiquid = food?.physicalState === 'liquid'
	const iconComponent = !standardFood ? Nutrition : isLiquid ? Liquid : Solid
	const cartoonImage = standardFood?.cartoonImage
	const sqUrl = cartoonImage?.smallUrl ?? image?.smallUrl
	const url = cartoonImage?.url ?? photoUrl
	const src = url && <Image src={sqUrl} preview={{ src: url }} />
	const avatar = (
		<Avatar icon={<Icon component={iconComponent} />} src={src} size={60} shape="square" />
	)
	const title = food?.name ?? t('standardFood:page.title')
	const desc = food?.description
	const description = desc !== title ? desc : null
	const extra = !standardFood ? (
		<LoadingOutlined />
	) : isAuthenticated ? (
		<Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={handleAdd} />
	) : null

	return (
		<>
			<PageIllustration $src={pattern} $isDarkTheme={isDarkTheme} />

			<PageAction style={{ position: 'relative' }}>
				<Card $size={'wide'} $isAction $withBigAvatar $withSquareAvatar>
					<Card.Meta
						avatar={avatar}
						title={renderLabel({ label: title, extra, isWrapped: true })}
						description={description}
					/>
				</Card>
				<FloatMenu style={{ position: 'absolute', bottom: 0, margin: '0 8px 8px 8px' }}>
					<NavButton
						icon={<ThunderboltOutlined />}
						disabled={!food}
						loading={food && !food.analysis}
						onClick={handleAnalyze}
					>
						{t('standardFood:actions.analyze')}
					</NavButton>
				</FloatMenu>
				<FloatMenu style={{ position: 'absolute', bottom: 0, right: 0, margin: '0 8px 8px 8px' }}>
					{!isAuthenticated ? (
						<NavButton icon={<LoginOutlined />} onClick={handleLogin}>
							{t('standardFood:actions.login')}
						</NavButton>
					) : (
						<NavButton
							icon={<HeartOutlined />}
							// onClick={handleFavorite}
							disabled
						>
							{t('standardFood:actions.markFavorite')}
						</NavButton>
					)}
				</FloatMenu>
				v
			</PageAction>

			<Tabs activeKey={section} items={items} onChange={handleSectionChange}></Tabs>
		</>
	)
}

export default StandardFood
